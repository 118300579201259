.close {
  position: sticky;
  width: 78px;
}

.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebar .logo-details img {
  color: #fff;
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  margin-left: 14px;
}

.sidebar .logo-details .logo_name {
  font-size: 35px;
  color: #d05d02;
  margin-left: 10px;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 0 0;
  overflow: auto;
}

.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
  background: #f2f2f2;
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close .nav-links li .iocn-link {
  display: block;
}

.sidebar .nav-links li i {
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.close .nav-links i.arrow {
  display: none;
}

.sidebar .nav-links li a,
.sidebar .nav-links li .link_SubMenu {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 58px;
}

.sidebar .nav-links li a .link_name,
.sidebar .nav-links li .link_SubMenu .link_name {
  font-size: 18px;
  font-weight: 400;
  transition: all 0.4s ease;
}

.close .nav-links li a .link_name,
.close .nav-links li .link_SubMenu .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #f2f2f2;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a,
.sidebar .nav-links li .sub-menu .link_name {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  transition: all 0.3s ease;
  height: auto;
}

.sidebar .nav-links li .sub-menu a:hover,
.sidebar .nav-links li .sub-menu .link_name:hover {
  opacity: 1;
}

.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .profile-details {
  position: sticky;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 12px 0;
  transition: all 0.5s ease;
}

.close .profile-details {
  background: none;
}

.close .profile-details {
  width: 78px;
}

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}

.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #f2f2f2;
  transition: all 0.5s ease;
}

.close .profile-details img {
  padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.close .profile-details svg,
.close .profile-details .profile_name,
.close .profile-details .job {
  display: none;
}

.sidebar .profile-details .job {
  font-size: 12px;
}

.home-section {
  position: fixed;
  height: 7vh;
  top: 14px;
  z-index: 99999;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}

.close .home-section {
  left: 65px;
  top: 14px;
  width: 40px;
  z-index: 99;
}

.home-section .home-content {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #000;
  height: 30px;
  width: 30px;
  border-radius: 50px;
}

.home-section .home-content i {
  font-size: 30px;
  color: #000;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 8px;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media (max-width: 400px) {
  .close .nav-links li .sub-menu {
    display: none;
  }

  .sidebar {
    width: 78px;
  }

  .close {
    width: 0;
  }

  .home-section {
    left: 78px;
    width: calc(100% - 78px);
    z-index: 100;
  }

  .close .home-section {
    width: 100%;
    left: 0;
  }
}
