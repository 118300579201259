.container {
  display: flex;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  align-items: flex-end;
  background: inherit;
}

.button {
  border: none;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 13px;
}

.dateListScrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: 2px 0 2px -40px;
  -webkit-overflow-scrolling: touch;

  background: inherit;
  width: 100%;
  position: relative;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0px);
  margin: 2px 0;
}

.dateListScrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.monthContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0;
  margin: 0px;
}

.yearContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 8px 120px;
  margin: 2px;
  align-items: center;
  color: rgba(0, 0, 0, 0.28);
}

.yearContainer:hover {
  color:var(--date-hover)
}

.monContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0px 38px;
  margin: 2px;
  align-items: center;
  letter-spacing: 1px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.28);
}

.monContainer:hover {
  color:var(--date-hover)
}

.monthYearLabel {
  align-self: flex-start;
  z-index: 3;
  font-size: 15px;
  font-weight: bold;
  position: sticky;
  top: 10px;
  left: 0;
  width: max-content;
  margin: 0 10px;
}

.dateDayItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 5px;
  width: 45px;
  height: 49px;
  flex-shrink: 0;
}

.dateDayItemMarked {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 5px;
  width: 45px;
  height: 70px;
  flex-shrink: 0;
  justify-content: center;
  color: rgba(0, 0, 0, 0.28);
}

.dateDayItemMarked:hover {
  color:var(--date-hover)
}

.daysContainer {
  display: flex;
  z-index: 1;
  margin-top: 10px;
}

.dayLabel {
  font-size: 12px;
  margin: 4px 0 0 0;
  text-transform: uppercase;
  color: #757575;
  font-weight: 600;
}

.dateLabel {
  font-size: 14px;
}

.markedLabel {
  margin-top: 10px;
}